import React, { useState, useEffect } from "react";
import { MinApp } from "../components/MinApp";
import { PageContext }from '../contexts/pageContext'
import Head from 'next/head';
import Script from 'next/script';
import { getConfig } from '../contexts/configs/index'

function FeedPage() {

  const shopDomain = process.env.NEXT_PUBLIC_API_URL;
  return (
    <>

      <Head>
        <title>Claros - Books</title>
        <link rel="icon" href="/logo.png" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <meta name="description" content="Discover the best books curated by AI for you." />
        <meta property="og:title" content="Claros - Books" />
        <meta property="og:description" content="Discover the best books curated by AI for you." />
        <meta property="og:url" content="https://www.claros.so/books" />
        <meta property="og:type" content="website" />
        <meta property="twitter:title" content="Claros - Books" />
        <meta property="twitter:description" content="Discover the best books curated by AI for you." />
      </Head>


    <PageContext.Provider value={getConfig("books")}>
        {/* 50% of the time, we want to show shitty app instead of app*/}
        <>
       {<MinApp/> }

        </>
    </PageContext.Provider>

    </>
  )
}
export default FeedPage
